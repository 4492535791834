<script setup lang="ts">
import { useI18n } from "vue-i18n"
import { useStorage } from "@vueuse/core"
import { authRoutes } from "@/router/index"
import { menuLightTheme, menuBrandTheme, menuGradientTheme, headerLightTheme } from "@/styles/light"
import { menuDarkTheme, headerDarkTheme } from "@/styles/dark"
import Iconfont from "@/assets/iconfont/custom-icon"
// import { message, theme } from "ant-design-vue"
import { UserOutlined, DownOutlined } from "@ant-design/icons-vue"
import { getRouteNameItem, removeToken } from "@/utils"
import theme from "ant-design-vue/es/theme"
import message from "ant-design-vue/es/message"
import closeDark from "@/assets/images/menu/close-dark.png"
import closeLight from "@/assets/images/menu/close-light.png"
import openDark from "@/assets/images/menu/open-dark.png"
import openLight from "@/assets/images/menu/open-light.png"
const { t } = useI18n()
const { token } = theme.useToken()

const userInfo = useStorage("USER_INFO", {
  dbuser_id: 0,
  data_center: "",
  name: "",
  created_at: "",
  contact: "",
  alias_name: "",
  url: ""
})

const themeKey = useStorage("THEME", "light")
const menuThemeKey = useStorage("MENU_THEME", "dark")
const headerThemeKey = useStorage("HEADER_MENU_THEME", "light")
const headerTheme = computed(() => {
  return headerThemeKey.value == "dark" ? headerDarkTheme : headerLightTheme
})
const menuTheme = computed(() => {
  const themeMap = {
    dark: menuDarkTheme,
    light: menuLightTheme,
    brand: menuBrandTheme,
    gradient: menuGradientTheme
  }
  type b = typeof themeMap
  type MenuThemeKeys = keyof b
  // type t = keyof [1, 2, 3]
  // type e = keyof { a: 1; b: 2; c: 3 }

  if (themeMap[menuThemeKey.value as MenuThemeKeys]) {
    return themeMap[menuThemeKey.value as MenuThemeKeys]
  } else {
    return themeMap["light"]
  }
})

const switchTheme = () => {
  const key = themeKey.value == "dark" ? "light" : "dark"
  if (key == "dark") {
    themeKey.value = "dark"
    headerThemeKey.value = "dark"
    menuThemeKey.value = "dark"
  } else {
    themeKey.value = "light"
    headerThemeKey.value = "light"
    menuThemeKey.value = "light"
  }
}

onMounted(() => {
  collapsed.value = (document.querySelector("body") as HTMLElement).clientWidth <= 1300
})

const collapsed = ref(true)
const router = useRouter()
const route = useRoute()
const activeRouteName = computed({
  get: () => {
    return [route.name]
  },
  set: (v) => {
    const result = router.getRoutes().find((item) => item.name == v[0])
    router.push({ ...result })
  }
})
const openKeys = reactive<Array<string>>([])
const breadList = computed(() => {
  return route.matched.slice(1, route.matched.length)
})
const getRouteMenuOptions = computed(() => {
  return authRoutes[0].children?.map((item) => {
    const routeName = activeRouteName.value[0]
    const isActive = item.name == routeName || item.children?.some((cItem) => cItem.name == routeName) || false
    let iconType: any = item.meta.icon
    if (isActive) {
      iconType += "-dianliang"
      openKeys.push(item.name as string)
    }
    return {
      key: item.name,
      icon: h(Iconfont, { type: iconType }),
      label: item.meta.title,
      children: item.children?.map((cItem) => {
        return {
          key: cItem.name,
          label: cItem.meta.title
        }
      })
    }
  })
})
const logoImgSrc = computed(() => {
  const state = collapsed.value ? "close" : "open"
  if (state == "close" && menuThemeKey.value == "light") {
    return closeLight
  } else if (state == "close" && menuThemeKey.value == "dark") {
    return closeDark
  } else if (state == "open" && menuThemeKey.value == "light") {
    return openLight
  } else if (state == "open" && menuThemeKey.value == "dark") {
    return openDark
  }
})
const logOut = () => {
  removeToken()
  localStorage.removeItem("HOST")
  message.success(t("common.logoutSuccessfully"))
  setTimeout(() => {
    router.replace("login")
  }, 500)
}
const goProfile = () => {
  router.push({ path: "/profile" })
}
const themeVarVisible = ref(false)
</script>
<template>
  <a-layout style="height: 100vh">
    <a-layout>
      <a-config-provider :theme="menuTheme">
        <a-layout-sider
          v-model:collapsed="collapsed"
          :style="{ background: menuTheme.token!.colorBgBase }"
          collapsible
          :trigger="null"
          :collapsedWidth="72"
          :width="240"
        >
          <div class="logo-cnr">
            <img :style="{ width: collapsed ? '27px' : '120px', objectFit: 'contain' }" :src="logoImgSrc" alt="" />
          </div>
          <a-menu
            v-model:selectedKeys="activeRouteName"
            :openKeys="collapsed ? [] : openKeys"
            :multiple="false"
            mode="inline"
            :items="getRouteMenuOptions"
          />
        </a-layout-sider>
      </a-config-provider>
      <a-layout>
        <a-config-provider :theme="headerTheme">
          <a-layout-header>
            <icon-font
              class="primary-icon"
              type="icon-zhankaishousuotubiao"
              @click="() => (collapsed = !collapsed)"
              style="margin-right: 24px"
            />
            <a-breadcrumb style="margin-right: auto">
              <a-breadcrumb-item v-for="item of breadList">
                <router-link :to="item"> {{ item.meta.title }} </router-link>
              </a-breadcrumb-item>
            </a-breadcrumb>
            <!-- <a-tooltip>
              <template #title>{{ t(themeKey == "dark" ? "common.darkMode" : "common.lightMode") }}</template>
              <icon-font
                class="primary-icon"
                :type="themeKey == 'dark' ? 'icon-ansetubiao' : 'icon-qiansetubiao'"
                @click="switchTheme"
                style="margin-right: 24px; font-size: 16px"
              />
            </a-tooltip> -->

            <select-lan style="margin-left: 0" />

            <a-dropdown>
              <div class="user-profile" style="margin: 0 24px">
                <div class="avater-wrap"><UserOutlined /></div>
                <span
                  style="
                    display: inline-block;
                    overflow: hidden;
                    margin-right: 11px;
                    max-width: 150px;
                    text-overflow: ellipsis;
                  "
                  >{{
                    userInfo.alias_name
                      ? userInfo.alias_name.length >= 11
                        ? userInfo.alias_name.slice(0, 11) + "..."
                        : userInfo.alias_name
                      : userInfo.name
                  }}</span
                >
                <DownOutlined style="font-size: 10px" />
              </div>
              <template #overlay>
                <a-menu>
                  <a-menu-item @click="goProfile">
                    <span>{{ t("profile.personCenter") }}</span>
                  </a-menu-item>
                  <a-menu-item @click="logOut">
                    <span>{{ t("common.logout") }}</span>
                  </a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <a-tooltip>
              <template #title>{{ t("common.settings") }} </template>
              <icon-font type="icon-zhutishezhi" class="primary-icon" @click="themeVarVisible = true" />
            </a-tooltip>
          </a-layout-header>
        </a-config-provider>
        <a-layout-content :class="['page-cnr', String(route.name) + '-cnr']">
          <router-view></router-view>
        </a-layout-content>
        <a-layout-footer style="padding: 26px 0 30px; text-align: center">
          ©2024 Delta Electronics, Inc. All Rights Reserved
        </a-layout-footer>
      </a-layout>
    </a-layout>
  </a-layout>
  <a-drawer v-model:open="themeVarVisible" width="260px">
    <div class="var-drawer-content">
      <!-- <a-typography-text>{{ t("common.colorScheme") }}</a-typography-text>
      <div class="check-wrap">
        <a-switch
          :checked="themeKey == 'light'"
          @update:checked="() => (themeKey == 'light' ? (themeKey = 'dark') : (themeKey = 'light'))"
        />
        <a-typography-text class="check-label">{{ t("common.lightMode") }}</a-typography-text>
      </div>
      <div class="check-wrap">
        <a-switch
          :checked="themeKey == 'dark'"
          @update:checked="() => (themeKey == 'light' ? (themeKey = 'dark') : (themeKey = 'light'))"
        />
        <a-typography-text class="check-label">{{ t("common.darkMode") }}</a-typography-text>
      </div> -->

      <a-typography-text>{{ t("common.menuScheme") }}</a-typography-text>
      <div class="check-wrap">
        <a-switch
          :checked="menuThemeKey == 'light'"
          @update:checked="() => (menuThemeKey == 'light' ? (menuThemeKey = 'dark') : (menuThemeKey = 'light'))"
        />
        <a-typography-text class="check-label">{{ t("common.light") }}</a-typography-text>
      </div>
      <div class="check-wrap">
        <a-switch
          :checked="menuThemeKey == 'dark'"
          @update:checked="() => (menuThemeKey == 'light' ? (menuThemeKey = 'dark') : (menuThemeKey = 'light'))"
        />
        <a-typography-text class="check-label">{{ t("common.dark") }}</a-typography-text>
      </div>
      <!-- <a-switch v-model:checked="checked" />
      <a-switch v-model:checked="checked" /> -->

      <!-- <a-typography-text>顶栏颜色</a-typography-text>
      <div class="check-wrap">
        <a-switch
          :checked="headerThemeKey == 'light'"
          @update:checked="() => (headerThemeKey == 'light' ? (headerThemeKey = 'dark') : (headerThemeKey = 'light'))"
        />
        <span class="check-label">浅色</span>
      </div>
      <div class="check-wrap">
        <a-switch
          :checked="headerThemeKey == 'dark'"
          @update:checked="() => (headerThemeKey == 'light' ? (headerThemeKey = 'dark') : (headerThemeKey = 'light'))"
        />
        <span class="check-label"> 深色</span>
      </div> -->
    </div>
  </a-drawer>
</template>

<style lang="less" scoped>
.var-drawer-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;

  .ant-typography {
    margin-bottom: 10px;
  }

  .check-wrap {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    .check-label {
      display: inline-block;
      margin-bottom: 0;
      margin-left: 10px;
      flex: 1;
    }
  }
}

.logo-cnr {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 64px;

  & img {
    display: inline-block;
    height: 30px;
    transition: all 0.2s, width 0s;
  }
}

.ant-layout-header {
  display: flex;
  align-items: center;
  padding: 16px;
  line-height: 32px;
  height: none;
  background: v-bind("token.colorBgLayout");
}

.page-cnr {
  display: flex;
  background: v-bind("token.colorBgLayout");
}

.ant-layout-content {
  margin: 0 16px;
}

.ant-breadcrumb {
  display: flex;
  align-items: center;
  height: 64px;
}

.user-profile {
  display: flex;
  cursor: pointer;

  .avater-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    width: 32px;
    height: 32px;
    background-color: v-bind("token.colorBgContainer");
    border-radius: 50%;

    & span {
      color: v-bind("token.colorPrimary");
    }
  }
}
</style>
