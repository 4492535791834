<script setup lang="ts">
import { selLang } from "@/utils/i18n"
import { useStorage } from "@vueuse/core"
const props = defineProps<{
  style?: any
}>()
const localeMap = {
  "zh-cn": "简体中文",
  "en-us": "English"
}
const locale = useStorage("LOCALE", "zn-cn")
</script>
<template>
  <a-dropdown trigger="click">
    <div class="drop-trigger" :style="$props.style">
      <icon-font type="icon-yuyan" style="font-size: 16px" />
      <span style="margin: 0 8px; font-size: 14px" class="default-text-button">{{ localeMap[locale] }}</span>
    </div>
    <template #overlay>
      <a-menu>
        <a-menu-item v-for="(label, key) in localeMap" @click="() => selLang(key)">{{ label }}</a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>
<style lang="less" scoped>
.drop-trigger {
  display: flex;
  align-items: center;
  margin-left: auto;
  cursor: pointer;
}
</style>
