<script lang="ts" setup>
import { computed, onMounted, reactive, ref, toRefs } from "vue"
const { t } = useI18n()

const hmiTipVisible = ref(false)
window.$vncModal = {
  open: () => (hmiTipVisible.value = true),
  close: () => (hmiTipVisible.value = false)
}

onMounted(() => {})
</script>
<template>
  <div>
    <a-modal v-model:open="hmiTipVisible" :width="640">
      <template #title>
        <div style="display: flex; align-items: center">
          <icon-font type="icon-tishi1" style="margin-right: 6px; color: #faad14; font-size: 28px"></icon-font>
          {{ t("billing.activedTips") }}
        </div>
      </template>
      <div style="min-height: 100px; margin: 15px 28px; font-size: 16px">
        <div style="height: 200px">
          <!-- <div class="after-tip">t('billing.trafficTips3')。</div> -->
          <div class="after-tip">{{ t("billing.vncEnd") }}</div>
        </div>
      </div>

      <template #footer>
        <a-button type="primary" @click="hmiTipVisible = false">
          {{ t("billing.know") }}
        </a-button>
      </template>
    </a-modal>
  </div>
</template>
<style lang="less" scoped>
.after-tip {
  background: #ebf8fe;
  border-radius: 4px;
  border: 1px solid #d1dbe0;
  margin-bottom: 80px;
  padding: 17px 32px;
}
</style>
