import { createI18n } from "vue-i18n"

import a_zhCN from "ant-design-vue/es/locale/zh_CN"
import a_enUS from "ant-design-vue/es/locale/en_US"
import zh from "./zh-cn"
import en from "./en-us"
import dayjs from "dayjs"
import "dayjs/locale/zh"
import "dayjs/locale/en"

export const messages = {
  "zh-cn": zh,
  "en-us": en
}
export const antdMessages = {
  "zh-cn": a_zhCN,
  "en-us": a_enUS
}

type Locales = keyof typeof messages

// 映射到本地有的
const mapNav2Locale = (): Locales => {
  const navLocale = navigator.language
  switch (navLocale.toLowerCase()) {
    case "zh-cn":
    case "zh-hans-cn":
    case "zh-tw":
    case "zh-hans-tw":
      // 繁体没有适配
      return "zh-cn"
    case "en-us":
    case "en-gb":
      return "en-us"
    default:
      return "zh-cn"
  }
}

const getLocaleStr = (): Locales => {
  const str = localStorage.getItem("LOCALE") as Locales
  if (str && Object.keys(messages).includes(str)) {
    return str
  } else {
    const lanStr = mapNav2Locale()
    return lanStr
  }
}
const locale = getLocaleStr()

export const i18n = createI18n({
  legacy: false,
  locale,
  globalInjection: true,
  messages
})
export const selLang = (langStr: any): void => {
  if (!Object.keys(messages).includes(langStr)) return
  localStorage.setItem("LOCALE", langStr)
  //删除计费Map
  localStorage.removeItem("CARD_TYPE_LIST")
  i18n.global.locale = langStr
  dayjs.locale(langStr.split("-")[0])
  history.go(0)
}
const initLang = (langStr: any): void => {
  if (!Object.keys(messages).includes(langStr)) return
  localStorage.setItem("LOCALE", langStr)
  i18n.global.locale = langStr
  dayjs.locale(langStr.split("-")[0])
}
initLang(locale)
