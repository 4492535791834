<script setup lang="ts">
import { lightTheme } from "./styles/light"
import { darkTheme } from "./styles/dark"
import { useStorage } from "@vueuse/core"
import { antdMessages } from "@/utils/i18n"
import styleInject from "./components/styleInject.vue"
import vncModel from "./components/vncModel.vue"
const themeKey = useStorage("THEME", "light")
const locale = useStorage("LOCALE", "zh-cn")
const theme = computed(() => {
  const theme = themeKey.value == "dark" ? darkTheme : lightTheme
  return theme
})
type localType = keyof typeof antdMessages
console.log(import.meta.env.VITE_HOST)
const locales = computed(() => {
  return antdMessages[locale.value as localType]
})
</script>

<template>
  <a-config-provider :theme="theme" :locale="locales" :dropdownMatchSelectWidth="false">
    <styleInject />
    <router-view></router-view>
    <use-loading />
    <vnc-model />
  </a-config-provider>
</template>
