import { createApp } from "vue"
import App from "./App.vue"
import { i18n } from "./utils/i18n"
import Router from "./router"
import "ant-design-vue/dist/reset.css"
import IconFont from "./assets/iconfont/custom-icon"
import message from "ant-design-vue/es/message"
import { createPinia } from "pinia"

message.config({
  duration: 2.5,
  maxCount: 3,
  rtl: true,
  prefixCls: "global-message"
})

const app = createApp(App).use(Router).use(i18n).use(createPinia())

app.component("IconFont", IconFont)

app.mount("#app")
