import { ThemeConfig } from "ant-design-vue/es/config-provider/context"
// import { theme } from "ant-design-vue"
import lightSeed from "./seed/lightSeed"
import theme from "ant-design-vue/es/theme"
const { darkAlgorithm, defaultAlgorithm } = theme
export const lightTheme: ThemeConfig = {
  token: {
    // SeedToken
    ...defaultAlgorithm(lightSeed),
    // MapToken
    controlHeightLG: 56,
    controlHeightSM: 32,
    controlHeightXS: 24,
    // padding: 12,
    // paddingLG: 20,
    // paddingMD: 16,
    // paddingSM: 8,

    //     paddingContentHorizontal		number	16
    // paddingContentHorizontalLG		number	24
    // paddingContentHorizontalSM		number	16
    // paddingContentVertical		number	12
    // paddingContentVerticalLG		number	16
    // paddingContentVerticalSM		number	8

    // AliasToken
  },
  components: {
    Checkbox: {
      controlInteractiveSize: 16
    },
    Message: {
      lineHeight: 40
    },
    Table: {
      colorTextHeading: "#6C6C6C"
    },
    Pagination: {
      borderRadius: 0
    },
    Modal: {
      controlHeightLG: 40,

    }
  }
}

// 菜单栏主题
export const menuLightTheme: ThemeConfig = {
  token: {
    // seed
    //激活文字颜色
    // colorPrimary: "#ffffff",
    // // 基础文字颜色
    // colorTextBase: "rgba(255,255,255,0.6)",
    // // 背景色
    colorBgBase: "#fff",
    // // 激活背景色
    // colorPrimaryBg: "#414753",
    // // 二级菜单背景色
    // colorFillQuaternary: "#000C17",
    // // hover填充色
    // colorFillSecondary: "#414753",
    // 圆角
    borderRadiusLG: 6,
    //item margin
    marginXXS: 8
    // tooltip
    // colorBgSpotlight: "#111926"
  },
  inherit: false
}

export const menuGradientTheme: ThemeConfig = {
  inherit: true
}
export const menuBrandTheme: ThemeConfig = {
  inherit: true
}

// 顶览主题
export const headerLightTheme: ThemeConfig = {
  token: {
    // seed
    //激活文字颜色
    // colorPrimary: "#ffffff",
    // // 基础文字颜色
    // colorTextBase: "rgba(255,255,255,0.6)",
    // // 背景色
    colorBgBase: "#fff",
    // // 激活背景色
    // colorPrimaryBg: "#414753",
    // // 二级菜单背景色
    // colorFillQuaternary: "#000C17",
    // // hover填充色
    // colorFillSecondary: "#414753",
    // 圆角
    borderRadiusLG: 6,
    //item margin
    marginXXS: 8
    // tooltip
    // colorBgSpotlight: "#111926"
  },
  inherit: false
}
