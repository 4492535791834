<script setup lang="ts">
import { convertCamelToKebab } from "@/utils"
import { useStorage } from "@vueuse/core"
import theme from "ant-design-vue/es/theme"
const { token } = theme.useToken()
const themeKey = useStorage("THEME", "light")

watchEffect(() => {
  const removeTagId = themeKey.value == "dark" ? "#light-theme-var" : "#dark-theme-var"
  const existingStyleTag = document.getElementById(removeTagId)
  if (existingStyleTag) {
    document.head.removeChild(existingStyleTag)
  }
  const styleTag = document.createElement("style")
  styleTag.type = "text/css"
  styleTag.id = themeKey.value == "dark" ? "#dark-theme-var" : "#light-theme-var"
  const cssVariables = `:root { ${Object.entries(token.value)
    .map(([key, value]) => `--sel-${convertCamelToKebab(key)}: ${value};`)
    .join("\n")}}`
  styleTag.appendChild(document.createTextNode(cssVariables))
  document.head.appendChild(styleTag)
})
</script>
<template></template>
